<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">

        <b-link class="brand-logo">
          <b-img
            :src="appLogoImage"
            alt="logo"
            class="img-fluid"
          />
        </b-link>

        <b-link class="brand-logo">
          <h2 class="text-primary text-center">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-text
          class="mb-2"
          style="text-align: center;"
        >
          Enter 6-digits code from your two factor authentication.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginValidation"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- Two Factor Authentication -->
            <b-form-group>
              <validation-provider>
                <div style="display:flex;">
                  <b-form-input
                    v-for="valueAuth in ValueAuth"
                    :key="valueAuth.input"
                    v-model="valueAuth.value"
                    type="number"
                    :formatter="formatInputTwoFactor"
                    placeholder=""
                    class="mr-v"
                    @keyup="focusNext($event.target, $event)"
                    @paste="pasteValidationCode"
                  />
                </div>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              class="mt-3"
              block
              @click="validationForm"
            >
              Verify
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardText, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
// import authService from '@core/services/auth/authService'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  data() {
    return {
      ValueAuth: [
        { input: '1', value: '' },
        { input: '2', value: '' },
        { input: '3', value: '' },
        { input: '4', value: '' },
        { input: '5', value: '' },
        { input: '6', value: '' },
      ],
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  methods: {
    formatInputTwoFactor(e) {
      return String(e).substring(0, 1)
    },
    focusNext(elem, event) {
      const currentIndex = Array.from(elem.form.elements).indexOf(elem)
      const lastIndex = elem.form.elements.length - 1
      let targetIndex

      if (event.keyCode === 8) {
        // Handle "Delete" key
        targetIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex
      } else if (event.keyCode === 37) {
        // Handle left arrow key
        targetIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex
      } else if (event.keyCode === 39) {
        // Handle right arrow key
        targetIndex = currentIndex < lastIndex ? currentIndex + 1 : 0
      } else if ((event.keyCode < 96 || event.keyCode > 105) && (event.keyCode < 48 || event.keyCode > 57)) {
        // Prevent non-numeric key presses
        event.preventDefault()
      } else {
        targetIndex = currentIndex < lastIndex ? currentIndex + 1 : 0
      }

      elem.form.elements.item(targetIndex).focus()
    },
    /* eslint-disable no-param-reassign */
    validationForm() {
      // const userData = this.$store.getters['user-auth/getUser']
      const verificationCode = this.ValueAuth.map(e => e.value).join('')
      if (!this.validateAuthyCode(verificationCode)) return
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Verified Correctly',
          text: 'Welcome!',
          icon: 'UnlockIcon',
          variant: 'success',
        },
      })
      this.$store.commit('user-auth/auth_success', { token: 'jvbowdfbvpwdfjvp', user: { last_name: 'Arias', name: 'Andrei' } })
      this.$router.push({ path: '/home' })
      // const authyObj = {
      //   id_partners_staff: userData.id_partners_staff,
      //   phone: userData.phone,
      //   token: verificationCode,
      // }
      // authService.verifyAuthyCode(authyObj)
      //   .then(response => {
      //     const { user, token } = response.data
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: 'Verified Correctly',
      //         text: 'Welcome!',
      //         icon: 'UnlockIcon',
      //         variant: 'success',
      //       },
      //     })
      //     this.$store.commit('user-auth/auth_success', { token: 'jvbowdfbvpwdfjvp', user: { last_name: 'Arias', name: 'Andrei' } })
      //     this.$router.push({ path: '/home' })
      //   })
      //   .catch(error => {
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: error.response?.data?.message || 'Logged In Error',
      //         icon: 'AlertTriangleIcon',
      //         variant: 'danger',
      //       },
      //     })
      //   })
    },
    validateAuthyCode(verificationCode) {
      let textMsg = ''
      if (verificationCode.length !== 6) textMsg = 'Verification Code most be 6 digits'
      if (!verificationCode.match(/^[0-9]+$/)) textMsg = 'Verification Code most be numbers only'
      if (textMsg !== '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Verification Code error',
            text: textMsg,
            icon: 'HashIcon',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
    pasteValidationCode(event) {
      event.preventDefault()
      const verificationCode = event.clipboardData.getData('text')
      if (!this.validateAuthyCode(verificationCode)) return
      this.ValueAuth.forEach((element, index) => {
        element.value = verificationCode[index]
      })
    },
    /* eslint-enable no-param-reassign */
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.mr-v{
    margin-right: 7px;
    margin-left: 7px;
    padding-left: 11px;
    padding-right: 0px;
}
</style>
